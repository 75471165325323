
<script>
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'CreditConcentrationChart',
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    showCode: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: null
    },
    value: Object
  },
  data() {
    return {
      fontSize: 16 // Initialize fontSize with a default value
    }
  },
  watch: {
    'chartdata.timestamp': {
      deep: true
    }
  },
  mounted () {
    this.setup()

    // Add event listener for resizing
    window.addEventListener('resize', this.updateFontSize)
  },
  methods: {
    updateFontSize() {
      let width = this.$refs.canvas ? this.$refs.canvas.offsetWidth : 16;
      let size = 0;
      if (width >= 2400) {
        size = 25
      } else if (width < 2400 && width >= 1500) {
        size = 21
      } else if (width < 1500 && width >= 1280) {
        size = 16
      } else {
        size = 10
      }
      this.fontSize = size;
    },

    setup () {
      let that = this
      this.updateFontSize();
      this.addPlugin(ChartDataLabels)

      let dataSet = { ...this.chartdata }
      let labels = []

      dataSet.labels.map(_label => {
        if (that.showCode) {
          labels.push(_label.code)
        } else {
          labels.push(_label.name)
        }
      })

      dataSet.labels = labels

      let datalabelsOptions = {
        formatter: (value) => {
          return this.$formatCurrency(value);
        },
        color: getComputedStyle(document.documentElement).getPropertyValue('--bcc-color').trim(),
        align: 'top',
        font: {
          size: this.fontSize
        }
      };

      // Create the chart
      this.renderChart(dataSet, {
        ...this.options,
        plugins: {
          datalabels: datalabelsOptions
        }
      });
    }
  }
}
</script>
